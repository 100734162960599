<template>
  <app-form-view
    app="team"
    model="team"
    api-url="team/team-header/"
    :title="$t('menu.team')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="teamHeaderName"
            rules="required"
            :label="$t('fields.team')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveteamHeaderName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>Member</v-tab>
          <v-tab>{{ $t('menu.region') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="table"
                  app="team"
                  model="teamdetail"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="headers"
                  :serverItems="TeamDetailItems"
                  :clientItems="TeamDetailItems"
                  :loading="loading"
                  :server-items-length="TeamDetailItemsLength"
                  v-model="TeamDetailSelected"
                  @server="getTeamDetailItems"
                  @client="getTeamDetailClientItems"
                  @delete="onDeleteItem"
                  @edit="onEdit"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title> Member </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogTeamDetail" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <slot name="actions">
                            <v-btn
                              v-show="mode === 'edit'"
                              dark
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              v-show="
                                TeamDetailSelected.length && mode === 'edit'
                              "
                              color="error"
                              @click="onDelete(TeamDetailSelected)"
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                          </slot>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedItem.contact_id"
                                    name="member"
                                    type="select-server"
                                    rules="required"
                                    :label="$t('fields.memberName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: `contact/contact/?active=true&is_employee=true`
                                    }"
                                  ></app-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeTeamDetail"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveTeamDetail"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Region Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="table"
                  app="team"
                  model="teamregion"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="teamRegionheaders"
                  :serverItems="TeamRegionItems"
                  :clientItems="TeamRegionItems"
                  :loading="loading"
                  :server-items-length="TeamRegionItemsLength"
                  v-model="TeamRegionSelected"
                  @server="getTeamRegionItems"
                  @client="getTeamRegionClientItems"
                  @delete="onDeleteTeamRegionItem"
                  @edit="onEditTeamRegion"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>
                        {{ $t('menu.region') }}
                      </v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogTeamRegion" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                          <slot name="actions">
                            <v-btn
                              v-show="mode === 'edit'"
                              dark
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                            >
                              New Item
                            </v-btn>
                            <v-btn
                              v-show="
                                TeamRegionSelected.length && mode === 'edit'
                              "
                              color="error"
                              @click="
                                onDeleteTeamRegionItem(TeamRegionSelected)
                              "
                            >
                              {{ $t('btn.delete') }}
                            </v-btn>
                          </slot>
                        </template>
                        <v-card>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedTeamRegionItem.region_id"
                                    name="regionName"
                                    type="select-server"
                                    :label="$t('fields.regionName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: `region/region/?active=true`
                                    }"
                                    @input="getDivisionItem"
                                  ></app-input>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="editedTeamRegionItem.division_id"
                                    name="divisionName"
                                    type="select-server"
                                    :label="$t('fields.divisionName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: divisionUrl
                                    }"
                                    :disabled="
                                      !Boolean(editedTeamRegionItem.region_id)
                                    "
                                    @input="getSubDivisionItem"
                                  ></app-input>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <app-input
                                    v-model="
                                      editedTeamRegionItem.sub_division_id
                                    "
                                    name="subDivisionName"
                                    type="select-server"
                                    :label="$t('fields.subDivisionName')"
                                    :view="view"
                                    :binds="{
                                      apiUrl: subDivisionUrl
                                    }"
                                    :disabled="
                                      !Boolean(editedTeamRegionItem.division_id)
                                    "
                                  ></app-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeTeamRegion"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveTeamRegion"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable.vue'

export default {
  name: 'team-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      tab: null,
      loading: false,
      formData: {
        state: 'draft'
      },
      approveItems: ['approve_name'],
      TeamDetailItems: [],
      TeamDetailSelected: [],
      TeamClientItems: [],
      TeamDetailItemsLength: 0,
      dialogTeamDetail: false,

      TeamRegionItems: [],
      TeamRegionSelected: [],
      TeamRegionClientItems: [],
      TeamRegionItemsLength: 0,
      dialogTeamRegion: false,

      editedItem: {
        sequence: null,
        question: null,
        question_type: null,
        choice: null,
        note: null
      },

      editedTeamRegionItem: {
        region_id: null,
        division_id: null,
        sub_division_id: null
      },
      divisionUrl: null,
      subDivisionUrl: null,
      apiMethod: 'post'
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    headers() {
      return [
        {
          text: this.$t('fields.memberName'),
          value: 'contact_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.department'),
          value: 'contact_id.role_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.cost'),
          value: 'contact_id.cost',
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    teamRegionheaders() {
      return [
        {
          text: this.$t('fields.regionName'),
          value: 'region_id.name'
        },
        {
          text: this.$t('fields.divisionName'),
          value: 'division_id.name'
        },
        {
          text: this.$t('fields.subDivisionName'),
          value: 'sub_division_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    getTeamDetailItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        const ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          team_header_id: this.$route.params.id
        }
      } else {
        this.params = { team_header_id: this.$route.params.id }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'team/team-detail/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.TeamDetailItemsLength = data.count
          this.TeamDetailItems = data.results
          this.TeamClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTeamDetailClientItems(multiSearch) {
      this.TeamClientItems = this.TeamDetailItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    closeTeamDetail() {
      this.editedItem = {}
      this.dialogTeamDetail = false
    },
    saveTeamDetail() {
      this.loading = true

      this.editedItem.team_header_id = this.$route.params.id

      const data = this.editedItem
      data.contact_id = this.editedItem.contact_id.id

      this.$api({
        method: this.apiMethod,
        url: `team/team-detail/${this.editId ? this.editId + '/' : ''}`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeTeamDetail()
      this.getTeamDetailItems()
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTeamDetail = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `team/team-detail/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    getTeamRegionItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        const ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          team_id: this.$route.params.id
        }
      } else {
        this.params = { team_id: this.$route.params.id }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'team/team-region/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.TeamRegionItemsLength = data.count
          this.TeamRegionItems = data.results
          this.TeamRegionClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTeamRegionClientItems(multiSearch) {
      this.TeamRegionClientItems = this.TeamRegionItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    closeTeamRegion() {
      this.editedTeamRegionItem = {}
      this.dialogTeamRegion = false
    },
    async saveTeamRegion() {
      this.loading = true
      const data = {}
      data.team_id = this.$route.params.id
      if (
        this.editedTeamRegionItem.region_id !== null &&
        this.editedTeamRegionItem.region_id !== undefined
      ) {
        data.region_id = this.editedTeamRegionItem.region_id.id
        if (
          this.editedTeamRegionItem.division_id !== null &&
          this.editedTeamRegionItem.division_id !== undefined
        ) {
          data.division_id = this.editedTeamRegionItem.division_id.id
        }
        if (
          this.editedTeamRegionItem.sub_division_id !== null &&
          this.editedTeamRegionItem.sub_division_id !== undefined
        ) {
          data.sub_division_id = this.editedTeamRegionItem.sub_division_id.id
        }
      }
      await this.$api({
        method: this.apiMethod,
        url: `team/team-region/${this.editId ? this.editId + '/' : ''}`,
        data
      })
      this.loading = false
      this.editedTeamRegionItem = {}
      this.closeTeamRegion()
      this.getTeamRegionItems()
    },
    onEditTeamRegion(item) {
      this.editedTeamRegionItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogTeamRegion = true
    },
    onDeleteTeamRegionItem(item) {
      this.onDeleteTeamRegion(item)
    },
    onDeleteTeamRegion(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `team/team-region/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    getDivisionItem() {
      var url = ''
      if (
        this.editedTeamRegionItem.region_id !== null &&
        this.editedTeamRegionItem.region_id !== undefined
      ) {
        url =
          'region/division/?active=true&region_id=' +
          this.editedTeamRegionItem.region_id.id
      } else {
        url = 'region/division/?active=true'
      }
      this.divisionUrl = url
    },
    getSubDivisionItem() {
      var url = ''
      if (
        this.editedTeamRegionItem.division_id !== null &&
        this.editedTeamRegionItem.division_id !== undefined
      ) {
        url =
          'region/sub-division/?active=true&division_id=' +
          this.editedTeamRegionItem.division_id.id +
          '&region_id=' +
          this.editedTeamRegionItem.region_id.id
      } else {
        url = 'region/sub-division/?active=true'
      }
      this.subDivisionUrl = url
    }
  }
}
</script>
